a {
    text-decoration: none!important;
}

.react-job-li {
    animation-fill-mode: both;
    animation-duration: 1000ms;
    animation-delay: 0ms;
    animation-iteration-count: 1;
    opacity: 1;
    animation-name: react-reveal-134780869363739-1;
}

.react-job-li li{
    background-color: #9B1118;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}

.react-job-li li .jbDet{
    margin-left: 48px;
    flex: 2 1 0%;
}

.react-job-li li .jbDet .title{
    color: rgb(255, 255, 255);
    /* font-family: Mark-Regular; */
    font-size: 30px;
    letter-spacing: -1.25px;
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}

.react-job-li li .jbDet div{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.react-job-li li .jbDet div p{
    color: rgb(253, 113, 64);
    font-family: Mark-Book;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: -0.58px;
    margin-right: 30px;
}

.react-job-li li .jbArrow{
    font-size: 48px;
    color: rgb(101, 10, 10);
    margin-right: 30px;
    opacity: 0.5;
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}

@media (pointer: fine) {
    .react-job-li li:hover {
        margin-left: -30px;
        margin-right: -30px;
        background-color: rgb(255, 255, 255);
        cursor: pointer;
    }
    .react-job-li li:hover .jbDet .title {
        color: rgb(192, 23, 34);
    }
    .react-job-li li:hover .jbArrow {
        color: rgb(192, 23, 34);
        opacity: 1;
    }
}

@media screen and (max-width: 576px) {
    .react-job-li li .jbDet .title {
        font-size: 18px;
    }
    .react-job-li li .jbDet div {
        margin-top: 10px;
    }
    .react-job-li li .jbArrow {
        font-size: 18px;
    }
}

@media screen and (max-width: 768px) {
    .react-job-li li .jbArrow {
        font-size: 32px;
    }
}

@media screen and (max-width: 992px) {
    .react-job-li li .jbDet {
        margin-left: 30px;
    }
    .react-job-li li .jbDet .title {
        font-size: 24px;
    }
    .react-job-li li .jbArrow {
        font-size: 38px;
    }
}