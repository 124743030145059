body {
  /* display: flx;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: #1d1f20; */
  /* height: 100vh; */
}
h1 {
  /* color: #f0f0f0; */
  /* font-size: 28px; */
  /* font-weight: 300; */
  /* font-family: sans-serif; */
  margin-bottom: 60px;
}
.app {
  /* display: flex;
  position: relative;
  flex-direction: column; */
  /* width: 600px; */
  /* background: white;
  height: calc(40vh + 48px);
  box-shadow: 0 5px 8px -4px rgba(0, 0, 0, 0.4); */
  padding: 20px 0;  
}
/* @media (max-width: 599px) {
  .app {
    width: 90%;
 }
} */
.app .nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  counter-reset: counter;
  /* margin-bottom: 20px; */
}
.app .nav a {
  position: relative;
  padding: 10px;
  width: calc(100%/5);
  text-align: center;
  /* font-family: sans-serif; */
  font-weight: bold;
  transition: all 0.3s ease-out;
  cursor: pointer;
  overflow: hidden;
}
.app .nav a.active{
  background-color: #bb0909;
  color: #ffffff;
}
/* .app .nav a:after {
  position: absolute;
  bottom: -100%;
  left: 50%;
  display: block;
  content: "";
  width: 0;
  height: 0;
  border: 10px solid transparent;
  transform: translateX(-50%);
  border-bottom-color: #eba8b0;
  transition: all 0.4s ease-out;
}
.app .nav a.active:after {
  bottom: 0;
} */
/* .app .nav a:hover {
  color: #eba8b0;
  background: rgba(235, 168, 176, 0.4);
}
.app .nav a:nth-child(2):hover {
  color: #73bfff;
  background: rgba(115, 191, 255, 0.4);
}
.app .nav a:nth-child(2):after {
  border-bottom-color: #73bfff;
}*/
.app .nav a:nth-child(5){
  color: #ff66cc;
}
.app .nav a:nth-child(5).active {
  /* border-bottom-color: #8dd6cd; */
  background-color: #ff66cc;
  color: #ffffff;
} 
.app .carousel-item:nth-child(5) .nav a.active, .app .carousel-item:nth-child(5) .nav {
  background-color: #ff66cc;
  color: #ffffff;
}
.app .item {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  /* height: 40vh; */
  margin: 0;
  padding: 40px 0;
}
.app .item h2 {
  color: white;
}
.app .item.fade-enter {
  opacity: 0;
}
.app .item.fade-enter-active {
  opacity: 1;
  transition: all 0.3s;
}
.app .item.fade-leave {
  opacity: 0;
}
.app .item.fade-leave-active {
  opacity: 0;
  transition: all 0.3s;
}

.app .item .video-block{
  font-size: 13px;
  font-weight: bold;
  color: #000000;
  text-align: center;
  padding: 0;
    margin: 0;
}
.app .item .video-block a{
  display: block;
}
.app .item .video-block img{
  width: 175px;
}
.app .woman .video-block{
  flex: 0 0 24%;
  max-width: 23%;
}
.app .woman .video-block img{
  width: 95%;
}
/* .app .item .video-block:last-child img{
  width: 200px;
} */
.app .item .video-block span, .slider-panel .app .carousel-item .video-block span {
  display: block;
  color: #bb0909;
  text-transform: uppercase;
}
.app .item .video-block strong, .slider-panel .app .carousel-item .video-block strong{
  display: block;
  text-transform: uppercase;
}

.slider-panel .carousel {
  padding: 0;
}
.slider-panel .carousel .carousel-indicators{
  display: none;
}

.slider-panel .carousel-control-next{
  bottom: auto;
  /* border: 1px solid red;
  background-color: #000000; */
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid #757272;
  right: 15%;
}

.slider-panel .carousel-control-prev{
  bottom: auto;
  width: 0; 
  height: 0; 
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;   
  border-right:20px solid #757272;
  left: 15%;
}

.app .carousel-item .nav {
  display: block;
  width: 50%;
  margin: 0 auto;
  background: #bb0909;
  padding: 8px;
  margin-bottom: 20px;
}

.slider-panel .app .carousel-item .video-block {
  float: left;
  margin-bottom: 20px;
  font-weight: bold;
  padding-left: 0;
}

.slider-panel {
  display: none;
}

.meet-our-people .x-head {
  height: 50px;
  background-position: center center;
  font-weight: bold;
  padding-left: 160px;
  text-align: center;
}

@media (max-width: 750px) {
  .slider-panel{
    display: block;
  }
  .meet-our-people .app {
    display: none;
  }
  .meet-our-people .x-head{
    background-position-x: left;
    padding: 11px 0 0 47px;
    text-align: left;
    font-size: 20px;
    margin: 10px 0 30px 0;
  }
}

@media (min-width: 992px) and (max-width: 1300px){
  .app .item .video-block {
    font-size: 12px;
  }
  .app .item .video-block img {
    width: 140px;
  }
  .app .woman .video-block img{
    width: 90%
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .app .item {
    display: block;
  }
  
  .app .item .video-block {
    float: left;
  }
  .app .nav a{
    font-size: 13px;
  }
}