.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

#page-wrap {
  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
  min-height: 100%;
  position: fixed;
}

/* Individual item */
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 50vh;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ffffff;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background-color: #9B1118;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 50px;
  justify-items: center;
  margin-top: 5%;
}

.box {
  height: 200px;
  width: 200px;
  box-shadow: 1px 1px 10px rebeccapurple;
}

.customBorder {
  border: 2px solid #c01722;
  border-radius: 10px;
  margin-bottom: 0;
  padding: 0.5rem 1.25rem 0rem;
}

.form-control.is-valid, .was-validated .form-control:valid {
  background-image: none;
}
.form-control.is-invalid, .was-validated .form-control:invalid {
  background-image: none;
}

.mobile_width {
  text-align: center;
  padding: 8px 0;
}

.entry-content {
  padding: 0 40px;
  text-align: justify;
}

.x-head{
  font-size: 1.75rem;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
  background-image: url("./images/xld-title.png");
  /* height: 50px; */
  padding: 6px 0 0 45px;
  background-repeat: no-repeat;
  margin: 15px 0 10px 0;
}

.removeMarginLR {
  margin-left: 0px;
  margin-right: 0px;
}

/*    MEDIA QUERIES     */
@media (max-width: 401px){
  #page-wrap {
    min-height: inherit;
    position: inherit;
  }
  .mobile_width {		
		text-align: center;
  }
  .x-head {
    font-size: 14px;
    font-weight: 600;
    padding: 16px 0 0 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .x-head span{
    display: none;
  }
}

@media (min-width:554px) and (max-width: 1025px){
  .x-head {
    font-size: 17px;
    padding-top: 12px;
  }
}

@media (min-width:400px) and (max-width: 555px){
  .x-head {
    font-size: 20px;
    padding-top: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .x-head span{
    display: none;
  }
}

.main-press-panel{
  display: block;
}
.main-press-panel .card-header{
  background-color: #9B1118;
  color: #ffffff;
  font-size: 1.2rem;
}
.main-press-panel .card-header:hover{
  background-color: #cb6468;
  cursor: pointer;
}
.main-press-panel .card-header .right-panel{
  display: inline-block;
  font-size: 1rem;
  padding: 2px 10px;
  background-color: #fff;
  color: #000;
  border-radius: 5px;
}

@media (min-width: 768px) and (max-width: 1050px) {
  .navbar-light .navbar-nav .nav-link{
    font-size: 20px !important;
  }
  .yearsOfCompletion img, .com-logo {
    height: 75px !important;
  }
}
@media (min-width: 1050px) and (max-width: 1250px) {
  .navbar-light .navbar-nav .nav-link{
    font-size: 22px !important;
  }
  /*.yearsOfCompletion img, .com-logo {
    /* height: 80px !important; 
  }*/
}

@media (min-width: 1100px) and (max-width: 1515px) {
  .home-page-card .x-head {
    font-size: 1.15rem;
    padding-top: 13px;
  }
}

@media (max-width: 1100px){
  .home-page-card .card {
    flex: 0 0 90%;
  }
}

.export-panel{
  right:0;
  float: right;
  display: block;
}
.export-panel table {
  height: auto;
  overflow: auto;
  border: none;
}
.export-panel table td{
  border: none;
}

.year-btn{
  background-color: #9B1118 !important;
}

.spinner-border{
  width: 1.5rem;
  height: 1.5rem;  
}
.bold {
  font-weight: bold;
}