.ribbon-wrapper {
	position: relative;z-index:998;
}
  .ribbon-front {
	background-color: #cc3333;	height: 50px;
	width: 250px;
	position: relative;
	left:-10px;
	z-index: 2; font:20px/50px bold Verdana, Geneva, sans-serif; color:#f8f8f8; text-align:center;text-shadow: 0px 1px 2px #cc6666;
}

  .ribbon-front,
  .ribbon-back-left,
  .ribbon-back-right
{
	-moz-box-shadow: 0px 0px 4px rgba(0,0,0,0.55);
	-khtml-box-shadow: 0px 0px 4px rgba(0,0,0,0.55);
	-webkit-box-shadow: 0px 0px 4px rgba(0,0,0,0.55);
	-o-box-shadow: 0px 0px 4px rgba(0,0,0,0.55);
}

  .ribbon-edge-topleft,
  .ribbon-edge-topright,
  .ribbon-edge-bottomleft,
  .ribbon-edge-bottomright {
	position: absolute;
	z-index: 1;
	border-style:solid;
	height:0px;
	width:0px;
}

  .ribbon-edge-topleft,
  .ribbon-edge-topright {
}

  .ribbon-edge-bottomleft,
  .ribbon-edge-bottomright {
	top: 50px;
}

  .ribbon-edge-topleft,
  .ribbon-edge-bottomleft {
	left: -10px;
	border-color: transparent #9B1724 transparent transparent;
}

  .ribbon-edge-topleft {
	top: -5px;
	border-width: 5px 10px 0 0;
}
  .ribbon-edge-bottomleft {
	border-width: 0 10px 0px 0;
}

  .ribbon-edge-topright,
  .ribbon-edge-bottomright {
	left: 220px;
	border-color: transparent transparent transparent #9B1724;
}

  .ribbon-edge-topright {
	top: 0px;
	border-width: 0px 0 0 10px;
}
  .ribbon-edge-bottomright {
	border-width: 0 0 5px 10px;
}


@-webkit-keyframes flow {
	0% { left:-20px;opacity: 0;}
	50% {left:100px;opacity: 0.3;}
    100%{ left:180px;opacity: 0;}
}
@keyframes flow {
	0% { left:-20px;opacity: 0;}
	50% {left:100px;opacity: 0.3;}
    100%{ left:180px;opacity: 0;}
}

.glow{ background: rgb(255,255,255); width:40px; height:100%; z-index:999; position:absolute;-webkit-animation: flow 1.5s linear infinite;-moz-animation: flow 1.5s linear infinite;-webkit-transform: skew(20deg);
	   -moz-transform: skew(20deg);
	     -o-transform: skew(20deg);background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 1%, rgba(255,255,255,1) 100%); /* FF3.6+ */
background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(1%,rgba(255,255,255,0)), color-stop(100%,rgba(255,255,255,1))); /* Chrome,Safari4+ */
background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%); /* Chrome10+,Safari5.1+ */
background: -o-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%); /* Opera 11.10+ */
background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%); /* IE10+ */
background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%); /* W3C */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */ border-left:1px solid #fff;}
