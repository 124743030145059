body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.themeColor{
  background-color: #C01722 !important;
  color: #fff !important;
}

.themeColor span{
    color: #C01722 !important;
}

.themeTextRed {
  color: #C01722 !important;
}

.themeBorder tr > td {
  border: 1px solid #750000;
}

.themeTextWhite {
  background-color: #C01722 !important;
  color: #fff !important;
}

.yearsOfCompletion{
  font: 400 2.33em/1.33 Impact, Arial Black, Helvetica Black, sans-serif;
}