.block-panel{
    display: block;
    border: 1px solid #e3e3e3;
    padding: 10px;
    border-radius: 5px;
    background-color: #f3f3f3;
    margin-bottom: 5px;
}
.block-panel h5, .block-panel h4 {
    text-align: center;
}
.custom-file-label span:nth-child(2){
    display: none;
}
.sub-header {
    font-size: 12px;
    font-weight: normal;
}
.custom-file .invalid-feedback, .custom-file .error-msg-block {
    top: 30px;
}
.emp-1-block {
    display: block;
}
.display-none {
    display: none;
}

.sign-out-btn{
    width: 100px;
    position: absolute;
    top: -56px;
}
.column-width{
    width: 159px;
    font-size: 12px;
}
table {
    overflow: scroll;
    display: block;
    height: 80vh;
}
#candidate a {
    color: #007bff;
}
#candidate a:hover {
    text-decoration: underline !important;
    color: #020c16;
}
.minimum-screen{
    padding-bottom: 10px;
}