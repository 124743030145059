/* Generic CSS */
a {
    color: #c01722;
}

.marge_imgR {
    margin-left: 15px;
    /* 1rem!important */
}
.marge_imgL {
    margin-right: 15px;
}

.quote {
    font-family: Georgia, serif;
    font-size: 18px;
    font-style: italic;
    margin: 0.25em 0;
    padding: 0.35em 40px;
    line-height: 1.45;
    position: relative;
}
.error-msg{
  
    color: #d00d20;
    font-weight: 100;
    
}
.error-msg-block{
    display: inline-block;
    background-color: #f1d0d0;
    margin: 10px 0;
    /* padding: 0 5px; */
}
.nav-link:hover, .btn-danger:hover{
    transform: initial !important;
}
.quote:before {
    display: block;
    padding-left: 10px;
    content: "\201C";
    font-size: 80px;
    position: absolute;
    left: -20px;
    top: -20px;
    color: #C01722;
}

.refer-class .social_links{
  z-index: 999;
}

.refer-class .carousel-indicators {
  bottom: -8px;
}
.carousel-indicators li{
  background-color:#802626;
}

.carousel-control-next-icon{
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 1px;
  transform: rotate(-45deg);
  background-image: none;
  -webkit-transform: rotate(-45deg);
}

.carousel-control-prev-icon{
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  border: solid black;
  border-width: 0 1px 1px 0;
  background-image: none;
  display: inline-block;
  padding: 1px;
  position: absolute;
  left: 15px;
}

/* Media queries */
@media (max-width: 576px){
    .mobile_img {
        margin: 10px auto;
        display: block;
        width: 100%;
    }
}

@media (min-width: 950px) and (max-width:1500px){
    .refer-class .social_links{
        top: -34px;
        right: -10px;
    }
}

.custom-file .invalid-feedback, .custom-file .error-msg-block{
    position: absolute;
    top:40px;
}
.apply-button {
    margin-top: 30px;
}
.contact-address{
  padding: 0 0 2rem 1.25rem;
}
.contact-address .address {
  padding-bottom: 25px;
}
/* refer related css */

.refer-class .collapsed, .refer-class .navbar-brand, .refer-class .navbar-nav, .refer-class .yearsOfCompletion, .refer-class .footer-carousel {
    display: none;
}
.refer-class .role-panel{
    display: block;
    padding: 10px 0;
    margin-bottom: 20px;
}
.refer-class .role-panel strong {
    font-size: 20px;
    padding-bottom: 10px;
    display: block;
}
.refer-class .navbar {
    POSITION: absolute;
    right: 14px;
    top: 121px;
    float: right;
}
.make-center{
    margin: 0 auto;;
}
.refer-class .refer-hide{
    display: none;
}
.refer-class .hide-list{
    display: none;
}
.about-title{
  /* margin-bottom: 30px; */
}
.about-title h2 {
    font-size: 1.2rem;   
    background-image: url("../images/Red-patch.png");
    padding: 10px;
    background-repeat: no-repeat;
    color: #ffffff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 460px;
}
.filename-trim{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}
.about-title p{
    padding: 10px 0 10px 0;
}
.about-title > span{
  display: block;
  padding: 20px 0;
}

.about-title .address-panel .address {
  /* border: 1px solid red; */
  /* border-right: 1px solid #c01722; */
  padding-bottom: 20px;
}
.about-title .address-panel .address strong{
  display: block;
}
.about-title .address-panel .new-address span{
  display: block;
  padding-left: 15px;
}

.refer-title{
    display: block;
    text-align: center;
    padding-bottom: 15px;
}
.refer-class .jumbotron{
    padding: 0;
}
.refer-class .customBorder{
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
}
.refer-container .info-container{
    /* margin-top: 40px; */
    display: block;
    text-align: center;
    font-size: 30px;
    padding-bottom: 30px;
}
.emp-panel{
  font-size: 14px;
  height: 472px;
  position: relative;
}
.emp-panel img {
  width: 250px;
}
.emp-panel span{
  display: block;
}
.emp-panel .pers-panel{
  padding-top: 70px;
  /* position: absolute; */
}
.emp-panel .pers-panel .name{
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}
.emp-panel .pers-panel .team{
  text-transform: uppercase;
  color: #FF0000;
  border-bottom: 1px solid #000000;
  border-top: 1px solid #000000;
}
.emp-panel .emp-info{
  background-image: url("../images/Testimonial-block.png");
  background-repeat: no-repeat;
  background-position: top;
  height: 100%;
  width: 100%;
  padding: 105px;
  font-size: 20px;
}  
.emp-panel.emp-info span {
  height: 270px;
  overflow: hidden;
}

@media (max-width: 1200px){
  .emp-panel .emp-info{
    background-image: none;
    border: 1px solid #e3e3e3;
    margin-top: 10px;
    border-radius: 5px;
  }
}
.refer-container .info-container strong span{
  color:#FF0000
}

.refer-class .themeTextRed{
    color: #000000 !important;
}

.refer-class .referCustomBorder{
    border: 2px solid #c01722;
    border-radius: 10px;
}

@media (max-width: 800px){
    .minimum-screen {
        max-width: 100%;
        flex: 0 0 100%;
        padding: 0;
        margin: 0;
    }
    .refer-class .referCustomBorder, .refer-class .small-screen{
        padding: 0;
    }
    
}

.refer-class .small-screen{
    max-width: 100%;
    flex: 0 0 100%;
}

.button-panel{
    display: flex;
    position: relative;
}
.button-panel button{
    width: 113px;
    height: 40px;
    font-weight: bold;
    position: absolute;
    right: 0;
}
.custom-file-input:lang(en)~.custom-file-label::after{
    background-color: #dc3545;
    color: #ffffff;
}

.show-refer {
    display: none;
}
.refer-class .show-refer{
    display: block;
}
.refer-class .hide-refer{
    display: none;
}

/* Share icons css */
.Demo__container {
    padding-top: 10px;
}
.Demo__some-network {
    vertical-align: top;
    display: inline-block;
    margin-right: 10px;
    text-align: center;
  }
  
  .Demo__some-network__share-count {
    display: inline-flex;
    justify-content: center;
    white-space: nowrap;
    overflow: visible;
    width: 0;
    margin-top: 3px;
    font-size: 12px;
  }
  
  .Demo__some-network__share-button {
    cursor: pointer;
  }
  
  .Demo__some-network__share-button:hover:not(:active) {
    opacity: 0.75;
  }
  
  .Demo__some-network__custom-icon {
    width:32px;
    height:32px;
  }


  /* login screen */
  .card-signin {
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
  }
  
  .card-signin .card-title {
    margin-bottom: 2rem;
    font-weight: 300;
    font-size: 1.5rem;
  }
  
  .card-signin .card-body {
    padding: 2rem;
  }
  
  .form-signin {
    width: 100%;
  }
  
  .form-signin .btn {
    font-size: 80%;
    border-radius: 5rem;
    letter-spacing: .1rem;
    font-weight: bold;
    padding: 1rem;
    transition: all 0.2s;
  }
  
  .form-label-group {
    position: relative;
    margin-bottom: 1rem;
  }
  
  .form-label-group input {
    height: auto;
    border-radius: 2rem;
  }
  
  .form-label-group>input,
  .form-label-group>label {
    padding: var(--input-padding-y) var(--input-padding-x);
  }
  
  .form-label-group>label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    /* Override default `<label>` margin */
    line-height: 1.5;
    color: #495057;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: all .1s ease-in-out;
  }
  
  .form-label-group input::-webkit-input-placeholder {
    color: transparent;
  }
  
  .form-label-group input:-ms-input-placeholder {
    color: transparent;
  }
  
  .form-label-group input::-ms-input-placeholder {
    color: transparent;
  }
  
  .form-label-group input::-moz-placeholder {
    color: transparent;
  }
  
  .form-label-group input::placeholder {
    color: transparent;
  }
  
  .form-label-group input:not(:placeholder-shown) {
    padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
    padding-bottom: calc(var(--input-padding-y) / 3);
  }
  
  .form-label-group input:not(:placeholder-shown)~label {
    padding-top: calc(var(--input-padding-y) / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: 12px;
    color: #777;
  }
  
  .btn-google {
    color: white;
    background-color: #ea4335;
  }
  
  .btn-facebook {
    color: white;
    background-color: #3b5998;
  }
  
  /* Fallback for Edge
  -------------------------------------------------- */
  
  @supports (-ms-ime-align: auto) {
    .form-label-group>label {
      display: none;
    }
    .form-label-group input::-ms-input-placeholder {
      color: #777;
    }
  }
  
  /* Fallback for IE
  -------------------------------------------------- */
  
  @media all and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
    .form-label-group>label {
      display: none;
    }
    .form-label-group input:-ms-input-placeholder {
      color: #777;
    }
  }
  :root {
    --input-padding-x: 1.5rem;
    --input-padding-y: .75rem;
  }

  .react-datepicker-wrapper{
      width: 100%;
      /* padding: 5px 0 20px 0 !important; */
  }
  .react-datepicker__close-icon::after{
      padding:2px 0 4px 0 !important;
  }

  .dataTable tbody tr td:last-child {
      width: 150px;
      display: block;      
      overflow-x: scroll;
      font-size: 13px;
    }

  .mdb-datatable-filter.flex-row{
      width: 80%;
      padding-bottom: 20px;
  }

  .mdb-datatable-filter.flex-row input {
    height: 40px;
    font-size: 16px;
    border: 1px solid #000000;
  }

  /* pagination css start */
  .pagination {
    width: 50%;
    margin: 0 auto;
    border: 1px solid #e3e3e3;
    margin-top: 20px;
  }
  .pagination li{
    width: 30%;
    text-align: center;
    cursor: pointer;
    border-left: 1px solid #e3e3e3;
    border-right: 1px solid #e3e3e3;
    padding: 5px;
  }
  .pagination li.active{
    background-color: #e3e3e3;
  }
  .pagination li a{
    display: block;
    width: 100%;
  }
  .pagination li a:focus{
    border: none;
    outline: none;
  }
  /* pagination css end */
  .draw-text{
    display: block !important;
    height: 48px !important;
  }
  .draw-section{
    margin: 0 auto;
    text-align: center;
    width: 200px;
    height: 100px;
    padding-top: 40px;
  }
  .main-panel{
    background: url('../images/functions/Left-side-design.png') 0 350px no-repeat, url('../images/functions/Right-side-design.png') 100% 350px no-repeat;
  }

  .body-panel{
    background: url('../images/functions/rangoli.png') no-repeat;
    color:#fff;
    background-position: top;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .body-panel .social_links{
    top: 250px;
    left: 10px;
  }
  .body-panel .social_links ul li a {
    color: #fff;
  }
  .wrapper{
    margin: 0 auto;
  }
  .wrapper-panel{
    text-align: center;
    border: 1px solid #e3e3e3;
    padding: 5px;
  }
  .counter{
    font-size: 35px;
    font-weight: bolder;
    color: #d81e1e;
  }
  .past-drawn-block .card-header {
    background-color: #d81e1e;
    color: #fff;
  }
  .past-drawn-item tr td {
    border: none;
    font-size: 20px;
    font-weight: 400;
  }
  .past-drawn-block{
    text-align: center;
  }
  .past-drawn-item tr td
  {
    font-size: 25px;
    font-weight: 500;
    /* padding: 0;
    line-height: 35px; */
  }
  .sample-text{
    line-height: 41px;
    font-weight: 400;
  }
  .expand-logo{
    margin: 0 auto;
    width: 240px;
  }
  .xld-logo{
    position: absolute;
    margin-left: 100px;
    margin-top: 0;
  }
  .main-panel-rangoli{
    margin-top: 250px;
  }
  .rangoli-title{
    text-align: center;
    padding-top: 50px;
    font-size: 90px;
    color: #f7f759;
  }
  .main-panel-rangoli .wrapper-panel{
    margin-top: 7rem!important;
  }

/* recruitment css */
.card-left{
  background-color: #ADFFAD;
  color: #ffffff;
  min-height: 320px;
  margin-bottom: 5px;
}
.card-left .card-header{
  background-color: #009D00;
  font-size: 18pt;
  text-align: center;
} 

.card-right{
  background-color: #EDEDED;
  color: #ffffff;
  min-height: 320px;
}

.card-right .card-header{
  background-color: #080909;
  font-size: 18pt;
  text-align: center;
}

.card-body-panel{
  color: #000000;
  font-size: 14pt;
}

.card-body-panel span{
  color: #C01722
}
.card-body-panel .text-right{
  font-size: 10pt;
  position: relative;
  top: 30px;
}
.card-body-panel .text-right a{
  color: blue;
  text-decoration: underline !important;
  cursor: pointer;
}

.rec-title{
  font-size: 14pt;
  margin-bottom: 35px;
}

.rec-title h2 {
  font-size: 18pt;   
  background-color: #d00d20;
  padding: 10px;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 40px 0 20px;
}

.rec-title p.title{
  text-align: center;
  font-size: 18pt;
  font-weight: bold;
  color: #d00d20;
  margin-bottom: 0;
}
.rec-title p.click-title {
  text-align: center;
  font-size: 13pt;
  font-weight: 500;
}
.rec-refer-title{
  font-size: 14pt;
  text-align: center;
  font-weight: bold;
}

.small-panel{
  border: 1px solid #d00d20;
  font-size: 13pt;
  min-height: 220px;
  margin-bottom: 5px;
}
.small-panel h3{
  font-size: 14pt;
  background-color: #d00d20;
  padding: 10px;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
.common-text{
  font-size: 13pt;
}