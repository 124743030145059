p {
    text-align: justify;
}

/* 
Generic Styling, for Desktops/Laptops 
*/
table, td, th {
    border: 1px solid black;
}

table { 
    width: 100%;
    border-collapse: collapse; 
}

th { 
    padding: 6px;
    text-align: center;
}

td { 
    padding: 6px; 
    text-align: justify; 
}

/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media 
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

    p {
        text-align: left;
    }

    td { 
        padding: 6px; 
        text-align: left; 
    }
}